// Here you can add other styles

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.full-height {
  display: flex;
  flex-direction: column;
  align-content: center;
  background: rgba(255, 255, 255, 1);
  width: -webkit-fill-available;
  border-radius: 10px;
  padding: 1rem;
  overflow: hidden;
  justify-content: space-between;
}

.scrollableY {
  overflow-y: scroll !important;
}

.bg-app {
  padding: 8rem !important;
  background-image: radial-gradient(#fff, #218dbf);
  @media (min-width: 768px) {
  }
  @media (max-width: 768px) {
    padding: 2rem !important;
  }
}

.bg-dashboard {
  padding: 50px 50px !important;
  background-image: radial-gradient(#fff, #218dbf);
  @media (min-width: 876px) {
  }
  @media (max-width: 876px) {
    padding: 2rem !important;
  }
}

.main_login_page {
  background-image: radial-gradient(#fff, #218dbf);
  .login_button_action {
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  #card-form-login {
    border: 0px !important;
    @media (max-width: 768px) {
      border-radius: 10px;
      margin-block: 10px;
    }
  }
  #login-logo {
    padding-inline: 1.5rem;
    border: 0px !important;
    @media (max-width: 768px) {
      display: none;
    }
  }
  #login-logo-mini {
    padding-inline: 1.5rem;
    border: 0px !important;
    display: none;
    @media (max-width: 768px) {
      width: -webkit-fill-available;
      display: block;
    }
  }
}

a.header-brand {
  font-weight: 600;
}

/* ## Border Radius ## */
.VMRadius {
  border-radius: 10px !important;
}

.VMLeftRadius {
  border-radius: 10px 0px 0px 10px;
}

.VMRightRadius {
  border-radius: 0px 10px 10px 10px;
}

.VMRadius-sm {
  border-radius: 5px !important;
}

.VMWrapModal {
  overflow: hidden !important;
  height: -webkit-fill-available;
  margin-block: auto;
  bottom: auto;
}

.VMWrapModalLoading {
  display: flex !important;
  overflow: hidden !important;
  align-items: center !important;
  justify-content: center !important;
}

.VMFontWeight {
  font-weight: 700;
}

.txtwhite {
  color: white !important;
}

label.form-check-label {
  color: currentColor;
  font-weight: 700;
}

select.form-select.select {
  color: currentColor;
  font-weight: 700;
}

input.form-control::placeholder {
  color: currentColor;
  font-weight: 700;
}

input.form-control:focus {
  outline: none;
  border-color: rgba(212, 234, 255, 0.459);
  box-shadow: 0 0 3px #39f;
}

select.form-select:focus {
  outline: none;
  border-color: rgba(212, 234, 255, 0.459);
  box-shadow: 0 0 3px #39f;
}

.form-check-input:checked {
  background-color: #39f;
  border-color: #39f;
}

@media (min-width: 576px) {
  html:not([dir='rtl']) .card-group > .card:not(:first-child) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  html:not([dir='rtl']) .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.content-dashboard {
  width: -webkit-fill-available;
  // margin-inline: 10rem;
  // display: flex;
  // justify-content: space-evenly;
}

.wrapper-footer2 {
  padding: 0px;
  border-width: 0px;
  overflow: hidden;
  width: -webkit-fill-available;
  margin-inline: 1rem;
  margin-top: 2rem;
  @media (min-width: 768px) {
    width: 26rem;
  }
  @media (max-width: 768px) {
  }
}

.btn-back {
  color: #fff !important;
  font-weight: 600;
  border-radius: 10px;
}

.btn-signout {
  position: fixed;
  top: 10px;
  right: 10px;
  color: #fff !important;
  font-weight: 600;
  border-radius: 7px;
}

#logo-klinik {
  min-width: 95px;
  width: 20vh;
  border-width: 0px;
}

.btn-dashboard {
  -webkit-box-shadow: 2px 2px 18px -2px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 2px 2px 18px -2px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 2px 18px -2px rgba(0, 0, 0, 0.47);
  padding: 0px;
  border-width: 0px;
  font-size: 1.5rem !important;
  border-radius: 15px;
  overflow: hidden;
  width: -webkit-fill-available;
  height: 130px;
  margin: 1rem;
  @media (min-width: 768px) {
    width: 26rem;
  }
  @media (max-width: 768px) {
    div.descbtn {
      p.title-dashboard {
        font-size: 1.9rem !important;
      }
      p.sub-title-dashboard {
        font-size: 0.9rem !important;
      }
    }
  }
  div.descbtn.centerin {
    line-height: 2.5rem;
    height: 8.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.descbtn {
    position: relative;
    transform: translateY(-410px);
    p {
      color: rgba(255, 255, 255, 0.87) !important;
      font-weight: 600;
      margin-bottom: 0;
      text-align: left;
      margin-inline: 20px;
    }
    p.title-dashboard {
      font-size: 2.1rem !important;
      margin-top: 20px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p.sub-title-dashboard {
      font-size: 1.1rem !important;
    }
  }
  .wave {
    position: relative;
    background: url('../../public/avatars/wave.svg') repeat-x;
    width: 6400px;
    height: 198px;
    bottom: -4.2rem;
    -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    -moz-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
  }
  .wave:nth-of-type(2) {
    bottom: 9rem;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 1;
  }

  @-moz-keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  @-webkit-keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  @keyframes swell {
    0%,
    100% {
      transform: translate3d(0, -25px, 0);
    }
    50% {
      transform: translate3d(0, 5px, 0);
    }
  }
}

.btn-dokter {
  -webkit-box-shadow: 2px 2px 18px -2px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 2px 2px 18px -2px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 2px 18px -2px rgba(0, 0, 0, 0.47);
  padding: 0px;
  border-width: 0px;
  font-size: 1.5rem !important;
  border-radius: 15px;
  overflow: hidden;
  width: -webkit-fill-available;
  height: 130px;
  margin: 1rem;
  @media (min-width: 768px) {
    // width: 26rem;
  }
  @media (max-width: 768px) {
    div.descbtn {
      p.title-dashboard {
        font-size: 1.9rem !important;
      }
      p.sub-title-dashboard {
        font-size: 0.9rem !important;
      }
    }
  }
  div.descbtn {
    position: relative;
    transform: translateY(-410px);
    p {
      color: rgba(255, 255, 255, 0.87) !important;
      font-weight: 600;
      margin-bottom: 0;
      text-align: left;
      margin-inline: 20px;
    }
    p.title-dashboard {
      font-size: 2.1rem !important;
      margin-top: 20px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p.sub-title-dashboard {
      font-size: 1.1rem !important;
    }
  }
  .wave {
    position: relative;
    background: url('../../public/avatars/wave.svg') repeat-x;
    width: 6400px;
    height: 198px;
    bottom: -4.2rem;
    -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    -moz-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
  }
  .wave:nth-of-type(2) {
    bottom: 9rem;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 1;
  }

  @-moz-keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  @-webkit-keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  @keyframes swell {
    0%,
    100% {
      transform: translate3d(0, -25px, 0);
    }
    50% {
      transform: translate3d(0, 5px, 0);
    }
  }
}

.header {
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  border-width: 0px;
  justify-content: center;
  flex-direction: column;
}

.VMmodal {
  z-index: 5;
  display: table;
  padding-top: 85px;
  padding-inline: 10%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  transform: scale(0);
}

.VMmodal.Open {
  transform: scale(1);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.VMmodal.Close {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

div.modal-content {
  border-radius: 15px;
  height: -webkit-fill-available !important;
}

.VMimg {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.VMcarouselItem {
  overflow: hidden;
  min-width: 24%;
  margin-inline: 0.5%;
  justify-content: space-between;
  // animation: carousel-antrian 2s infinite;
  h5.card-title.antrian {
    display: flex;
    align-content: center;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    border-color: #39f !important;
    border: 1px solid var(--cui-card-border-color, #39f);
    border-inline: none;
    border-bottom: none;
    width: -webkit-fill-available;
    margin-inline: 3vh !important;
    height: 4rem !important;
  }
}

@keyframes carousel-antrian {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-102%);
  }
}

.head-antrian {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: -webkit-fill-available;
  height: 39vh;
  margin-bottom: 1%;
}

.header-logo-mini {
  min-width: 70px;
  width: 10vh;
  border-width: 0.2rem;
  border-radius: 10px;
  overflow: hidden;
  border-color: rgba(0, 0, 0, 0);
}
.header.dashboard-antrian {
}
.card.head-antrian-green {
  display: flex;
  overflow: hidden;
  border-color: #2eb85c !important;
  width: -webkit-fill-available;
  border-radius: 10px !important;
  margin-inline: 0.5%;
  div.card-header {
    background: #2eb85c !important;
    color: white;
    h1.antrian {
      font-size: 5vh;
      height: 5vh;
    }
  }
  div.card-body {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    h1.antrian {
      font-size: 11vh;
      height: 11vh;
    }
    h2.antrian {
      font-size: 5vh;
    }
    h5.card-title {
      font-size: 1vh;
      height: 1vh;
    }
  }
  h5.card-title.antrian {
    height: 7vh;
    margin-bottom: 0px;
    display: flex;
    align-content: center;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    border-color: #2eb85c !important;
    border: 1px solid var(--cui-card-border-color, #2eb85c);
    border-inline: none;
    border-bottom: none;
    width: -webkit-fill-available;
    margin-inline: 3vh;
  }
}
.card.head-antrian-left {
  display: flex;
  overflow: hidden;
  border-color: #39f !important;
  width: -webkit-fill-available;
  border-radius: 10px !important;
  margin-inline: 0.5%;
  div.card-body {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    h1.antrian {
      font-size: 16vh;
    }
    h2.antrian {
      font-size: 5vh;
    }
  }
}
.bg-head-antrian-right {
  position: absolute;
  display: flex;
  height: -webkit-fill-available;
  width: 100%;
  padding: 10px;
  justify-content: center;
}
.card.head-antrian-right {
  display: flex;
  overflow: hidden;
  border-color: #39f !important;
  width: -webkit-fill-available;
  border-radius: 10px !important;
  margin-inline: 0.5%;
  div.card-header {
    background-color: rgba(255, 255, 255, 0.9);
    border-width: 0;
    z-index: 1;
  }
  div {
    h2 {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical;
    }
  }
}
.header-title {
  background-color: rgba(255, 255, 255, 0);
  border-width: 0;
}
.card-body.head-antrian-right {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  h5,
  h4,
  h3,
  h2,
  h1 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    color: rgba(0, 0, 0, 0.5);
  }
  h3 {
    -webkit-line-clamp: 3 !important;
  }
}
.VMcarousel {
  display: flex;
  overflow: hidden;
  height: 15rem;
}

.wraper-2lines-antrian {
  line-height: 1.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .h3,
  .h4,
  .h5 {
    margin: 0;
    display: -webkit-box;
    height: -webkit-fill-available;
    overflow: hidden;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
  }
}

// Style keyboard

div.hg-button.hg-standardBtn {
  background-color: #f9b115 !important;
  color: white !important;
  font-weight: 600 !important;
  // new
  // flex-grow: unset !important;
  // border-radius: 50% !important;
  // display: flex !important;
  // height: 100px !important;
  // justify-content: center !important;
  // width: 100px !important;
}
div.hg-button.hg-button.hg-functionBtn {
  background-color: #f9b115 !important;
  color: white !important;
  font-weight: 600 !important;
}

div.react-simple-keyboard.simple-keyboard.hg-theme-default.hg-layout-numeric.numeric-theme.hg-layout-default {
  // display: flex !important;
  // justify-content: center !important;
}

.bg-app-antrian {
  background: url('../../public/avatars/bg-01.svg') no-repeat;
  background-size: cover;
  border-radius: 0 !important;
}

.logo-copy-right {
  height: 3vh;
  background: white;
  padding-inline: 4px;
  padding-block: 2px;
  border-radius: 4px;
  margin-right: 1vh;
}

.logo-antrian-klinik {
  width: 8vh;
  border-radius: 4px;
  margin-right: 1vh;
}

div.header-antrian-klinik {
  height: 8vh;
  overflow: hidden;
  p.nama-klinik {
    font-size: 2.4vh;
    font-weight: 600;
  }
  p.identitas-klinik {
    font-size: 1.5vh;
    font-weight: 500;
  }
  p.tanggal-antrian p.jam-klinik {
    font-size: 3vh;
    font-weight: 500;
  }
}

/* Hidden Arrow Attribute On Input Type Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.disclosure-header {
  border: 1px solid;
  border-color: #2eb85c;
  margin-bottom: auto;
  // border-radius: 1rem;
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
  //   0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.01), 0 4px 2px rgba(0, 0, 0, 0.01),
    0 8px 4px rgba(0, 0, 0, 0.01), 0 16px 8px rgba(0, 0, 0, 0.01), 0 32px 16px rgba(0, 0, 0, 0.01);
}
